import { ActionsObservable, StateObservable, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { AppActions } from '../../actions'
import { ScrollToTopAction } from '../../actions/ui'
import { RootState } from '../../reducers'
import { getIsArchiveView } from '../../selectors/settingsSelectors'
import { SetActiveArticleAction } from '../../actions/articles'

const scrollToTop = (action$: ActionsObservable<AppActions>, { state$ }: { state$: StateObservable<RootState> }) =>
  action$.pipe(
    ofType<AppActions, ScrollToTopAction>('SCROLL_TO_TOP'),
    switchMap(() => {
      const state = state$.value
      const isArchiveView = getIsArchiveView(state)
      const articlePreviewsRef = document.getElementById('article-previews')

      if (articlePreviewsRef) {
        articlePreviewsRef.scrollTop = 0
      }

      if (isArchiveView) {
        return of()
      }

      return of<SetActiveArticleAction>({ type: 'SET_ACTIVE_ARTICLE', payload: { index: 0, source: 'scroll' } })
    }),
  )

export default [scrollToTop]
