import { ChevronDown16Regular } from '@fluentui/react-icons'
import { Checkbox, CheckboxProps, cn, Label } from '@opoint/infomedia-storybook'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { forwardRef, LiHTMLAttributes, MouseEventHandler, useState } from 'react'

import { useMatches, useNavigate, useParams } from 'react-router-dom'
import { useOpointUser } from '@opoint/authjs-react'
import { isNavigationItemChecked } from '../../../../helpers/navigation'
import useSearchRouteBuilder, { SearchRouteBuilderAction } from '../../../hooks/useSearchRouteBuilder'

import { SearchFilterKey } from '../../../hooks/useSearchFilters'
import NavigationNewMentionsBadge from './NavigationNewMentionsBadge'

export type NavigationItem = { id: number; name: string; owner?: number; children?: NavigationItem[] }

type NavigationItemMultipleProps = {
  item: NavigationItem
  type: SearchFilterKey
  selectedItemsIds: number[]
  searchMode?: boolean
} & Omit<LiHTMLAttributes<HTMLLIElement>, 'id' | 'children'>

export const NavigationItemMultiple = forwardRef<HTMLLIElement, NavigationItemMultipleProps>(
  ({ item, type, selectedItemsIds, className, searchMode, ...props }, ref) => {
    const user = useOpointUser()
    const params = useParams()
    const profileId = Number(params.profileId)
    const navigate = useNavigate()
    const matches = useMatches()
    const { getSearchRoute, getProfileEditorRoute } = useSearchRouteBuilder()

    const isNewProfileEditor = matches.find(({ pathname }) => pathname === '/profiles')
    const isLegacyProfileEditor = matches.find(
      ({ pathname }) => pathname === '/search/edit/' || pathname === '/search/new',
    )
    const selectedItems = isNewProfileEditor ? [profileId] : selectedItemsIds
    const checked = isNavigationItemChecked(item, selectedItems)
    const [isExpanded, setIsExpanded] = useState(checked === 'indeterminate')
    const isProfile = type === SearchFilterKey.PROFILES

    // In the profile builder navigation, disable the navigation link if the user is not the owner of the profile (do not allow profile editing)
    const isDisabled = (isNewProfileEditor || isLegacyProfileEditor) && item.owner !== user?.user_id

    // If checkbox is indeterminate, set to false when children are expanded
    const isCheckboxChecked = checked === 'indeterminate' && isExpanded ? false : checked

    const handleCheckboxCheckedChange: CheckboxProps['onCheckedChange'] = () => {
      navigate(getSearchRoute(type, item.id, SearchRouteBuilderAction.Toggle))
    }

    const handleLabelClick: MouseEventHandler<HTMLLabelElement> = () => {
      navigate(getProfileEditorRoute(item.id))
    }

    const itemHeader = (
      <>
        <span
          className={cn('flex h-6 items-center justify-center', {
            'w-6': !searchMode,
            invisible: isDisabled,
          })}
        >
          <Checkbox
            variant="menu"
            id={item.id.toString()}
            checked={isCheckboxChecked}
            onCheckedChange={handleCheckboxCheckedChange}
          />
        </span>

        <Label
          onClick={handleLabelClick}
          title={item.name}
          className={cn('ml-1.5 block flex-1 cursor-pointer truncate text-label-l text-grey.1', {
            'font-bold text-sky.1': checked,
            'text-grey.2 pointer-events-none': isDisabled,
          })}
        >
          {item.name}
        </Label>
      </>
    )

    if (!item?.children?.length) {
      return (
        <li
          ref={ref}
          className={cn('flex h-10 items-center gap-x-1.5 px-4', className)}
          {...props}
          data-cy={isProfile ? 'navigationProfileItem' : undefined}
        >
          {itemHeader}

          {isProfile && (
            <div className="flex min-w-[1.5rem] justify-center">
              <NavigationNewMentionsBadge profileId={item.id} />
            </div>
          )}
        </li>
      )
    }

    return (
      <CollapsiblePrimitive.Root asChild open={isExpanded} onOpenChange={setIsExpanded}>
        <li ref={ref} className={cn('flex flex-col', className)} {...props}>
          <span className="flex h-10 items-center gap-x-1.5 px-4">
            {itemHeader}
            {isProfile && <NavigationNewMentionsBadge profileId={item.id} />}
            <CollapsiblePrimitive.Trigger className="group/trigger flex h-6 w-6 items-center justify-center rounded text-sky.1">
              <ChevronDown16Regular className="group-data-[state=open]/trigger:rotate-180" />
            </CollapsiblePrimitive.Trigger>
          </span>

          <CollapsiblePrimitive.Content asChild>
            <ul className="ml-5">
              {(item?.children || []).map((item) => (
                <NavigationItemMultiple key={item.id} item={item} selectedItemsIds={selectedItemsIds} type={type} />
              ))}
            </ul>
          </CollapsiblePrimitive.Content>
        </li>
      </CollapsiblePrimitive.Root>
    )
  },
)
NavigationItemMultiple.displayName = 'NavigationItemMultiple'
