const isTextValid = (text?: string) => {
  const trimmed = text?.trim()?.replace(' ', '')

  // we get exception on every empty html tag because of the bug in draft-js
  // so this is just few most used tag in portal which cause us problems
  return (
    trimmed &&
    trimmed !== '<p></p>' &&
    trimmed !== '<p><br></p>' &&
    trimmed !== '<span></span>' &&
    trimmed !== '<span><br></span>' &&
    trimmed !== '<div></div>' &&
    trimmed !== '<div><br></div>'
  )
}

export default isTextValid
