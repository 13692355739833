import { ChevronLeft20Regular } from '@fluentui/react-icons'
import { Popover, PopoverContent, PopoverContentProps, PopoverTrigger } from '@opoint/infomedia-storybook'
import { PropsWithChildren, useState } from 'react'
import { AlertTag as AlertTagType, GeneralTag } from '../types/tag'

import ErrorBoundary from '../common/ErrorBoundary'
import { M360Article } from '../../opoint/articles/types'
import KeywordTag from './KeywordTag'
import TagSwitch from './TagSwitch'

type OverflowTagsPopoverProps = PropsWithChildren<{
  tags: GeneralTag[]
  article: M360Article
  side?: PopoverContentProps['side']
}>

const OverflowTagsPopover = ({ tags, article, side = 'top', children }: OverflowTagsPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [overflowDrilldown, setOverflowDrilldown] = useState<AlertTagType | null>(null)

  const handleOpenChange = () => {
    if (!isOpen) {
      setOverflowDrilldown(null)
    }

    setIsOpen((prev) => !prev)
  }

  const renderDrilldownTitle = () => (
    <button
      className="flex min-h-[2.5rem] w-full shrink-0 cursor-pointer items-center gap-1 border-b border-grey.6 px-4 pb-4"
      onClick={() => {
        setOverflowDrilldown(null)
      }}
    >
      <ChevronLeft20Regular className="shrink-0" />
      <span className="text-left">{overflowDrilldown?.subject}</span>
    </button>
  )

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent side={side} className="px-0 py-4">
        <ErrorBoundary reloadButton={false} errorImg={false} contactSupport={false}>
          <div id="tags" className="flex max-h-[27.5rem] w-80 flex-col overflow-auto px-0">
            {overflowDrilldown?.subject && renderDrilldownTitle()}
            {/* @ts-expect-error: Muted so we could enable TS strict mode */}
            {overflowDrilldown?.children.length > 0 ? (
              // @ts-expect-error: Muted so we could enable TS strict mode
              overflowDrilldown.children.map((innerTag) => (
                <KeywordTag key={innerTag.id} article={article} tag={innerTag} isOverflow />
              ))
            ) : (
              <TagSwitch article={article} tags={tags} isOverflow setOverflowDrilldown={setOverflowDrilldown} />
            )}
          </div>
        </ErrorBoundary>
      </PopoverContent>
    </Popover>
  )
}

export default OverflowTagsPopover
