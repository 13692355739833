import { FC } from 'react'

import { Home16Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom'
import { AlertsDetailBreadcrumb } from './components/alerts/AlertsTable/AlertsDetailBreadcrumb.tsx'
import RequireAuthentication from './components/auth/RequireAuthentication'
import SuspenseWithLoading from './components/common/Modal/SuspenseWithLoading'
import { UserManagementActionBar } from './components/layout/actionBar/userManagement/UserManagementActionBar.tsx'
import { UserDetailBreadcrumb } from './components/userManagement/UserDetail/UserDetailBreadcrumb'
import UserManagementView from './components/userManagement/UserManagementView/index.tsx'
import { lazy } from './helpers/lazy'
import AlertsPage from './pages/alerts/AlertsPage.tsx'
import { default as App } from './pages/app/App'
import CustomerViewPage from './pages/customer_view/CustomerViewPage.tsx'
import SingleArticleView from './pages/article/SingleArticleView'
import { ModulePermissionCheck } from './pages/permissions/ModulePermissionCheck.tsx'
import ProfilesPage from './pages/profiles/ProfilesPage.tsx'
import { ProfileEditBreadcrumb } from './components/profile/builder/breadcrumbs/ProfileEditBreadcrumb.tsx'
import { ProfilesBreadcrumb } from './components/profile/builder/breadcrumbs/ProfilesBreadcrumb.tsx'

const AlertsDashboard = lazy(() => import('./components/alerts/AlertsDashboard.tsx'))
const SearchPage = lazy(() => import('./pages/search/SearchPage'))
const CreateProfilePage = lazy(() => import('./pages/profiles/CreateProfilePage'))
const EditProfilePage = lazy(() => import('./pages/profiles/EditProfilePage'))
const ArticleView = lazy(() => import('./pages/article/ArticleView'))
const MobileView = lazy(() => import('./pages/mobile/MobileView'))
const AddAlert = lazy(() => import('./components/alerts/AddAlert'))
const EditAlert = lazy(() => import('./components/alerts/EditAlert'))
const StatisticsPage = lazy(() => import('./pages/statistics/StatisticsPage'))
const Statistics = lazy(() => import('./components/statistics'))
const StatisticsComparison = lazy(() => import('./components/statistics/statisticsComparison'))
const ProfileEditor = lazy(() => import('./components/profile/ProfileEditor'))
const ViewAlert = lazy(() => import('./components/alerts/ViewAlert'))
const CreateFeedPage = lazy(() =>
  import('./components/feeds/CreateFeedPage').then((m) => ({ default: m.CreateFeedPage })),
)
const EditFeedPage = lazy(() => import('./components/feeds/EditFeedPage').then((m) => ({ default: m.EditFeedPage })))
const AlertMain = lazy(() => import('./components/alerts/AlertMain'))
const FrontPagesView = lazy(() => import('./pages/frontpages/FrontPagesView'))
const UserManagement = lazy(() => import('./components/userManagement'))
const UserDetail = lazy(() => import('./components/userManagement/UserDetail'))
const CustomerView = lazy(() => import('./components/impersonation/CustomerView'))
const FoldersPage = lazy(() => import('./pages/folders'))
const CallbackPage = lazy(() => import('./pages/Callback'))
const LoggedOutPage = lazy(() => import('./pages/logged-out'))
const MobileArticle = lazy(() => import('./components/mobile/MobileArticle'))
const MobileFoldersList = lazy(() => import('./components/mobile/MobileFoldersList'))
const MobileListPage = lazy(() => import('./pages/mobile/list/MobileListPage'))
const NotFoundErrorPage = lazy(() => import('./pages/errors/NotFoundErrorPage'))

export const router = createBrowserRouter([
  {
    path: '/',
    handle: {
      crumb: <Home16Regular />,
    },
    element: <RequireAuthentication as={App} />,
    children: [
      // search - profiles, trash, edit alert content
      {
        path: 'search',
        element: (
          <SuspenseWithLoading>
            <SearchPage />
          </SuspenseWithLoading>
        ),
        children: [
          {
            path: 'new',
            element: (
              <SuspenseWithLoading>
                <ProfileEditor />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'edit',
            element: (
              <SuspenseWithLoading>
                <ProfileEditor />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'article',
            element: (
              <SuspenseWithLoading>
                <SingleArticleView />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'profiles',
        element: <ProfilesPage />,
        handle: {
          crumb: <ProfilesBreadcrumb />,
        },
        children: [
          {
            index: true,
            element: <Navigate to="/profiles/new" />,
          },
          {
            path: 'new',
            handle: {
              crumb: <T _str="Create new profile" />,
            },
            element: (
              <SuspenseWithLoading>
                <CreateProfilePage />
              </SuspenseWithLoading>
            ),
          },
          {
            path: ':profileId',
            handle: {
              dynamic: true,
              crumb: <ProfileEditBreadcrumb />,
            },
            element: (
              <SuspenseWithLoading>
                <EditProfilePage />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'feeds',
        element: (
          <ModulePermissionCheck module="FEED_MODULE">
            <Outlet />
          </ModulePermissionCheck>
        ),
        children: [
          {
            path: 'new',
            element: (
              <SuspenseWithLoading>
                <CreateFeedPage />
              </SuspenseWithLoading>
            ),
          },
          {
            path: ':feedId',
            element: (
              <SuspenseWithLoading>
                <EditFeedPage />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'statistics',
        element: (
          <SuspenseWithLoading>
            <StatisticsPage />
          </SuspenseWithLoading>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWithLoading>
                <Statistics />
              </SuspenseWithLoading>
            ),
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: (
                  <SuspenseWithLoading>
                    <Statistics />
                  </SuspenseWithLoading>
                ),
              },
              {
                path: 'compare',
                element: (
                  <SuspenseWithLoading>
                    <StatisticsComparison />
                  </SuspenseWithLoading>
                ),
              },
            ],
          },
          {
            path: 'compare',
            element: (
              <SuspenseWithLoading>
                <StatisticsComparison />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'alerts',
        handle: {
          crumb: <T _str="Alerts" />,
        },
        element: <AlertsPage />,
        children: [
          {
            index: true,
            element: (
              <SuspenseWithLoading>
                <AlertsDashboard />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'new',
            handle: {
              crumb: <T _str="Create new alert" />,
            },
            element: (
              <SuspenseWithLoading>
                <AddAlert />
              </SuspenseWithLoading>
            ),
          },
          {
            path: ':alertId',
            handle: {
              dynamic: true,
              crumb: <AlertsDetailBreadcrumb />,
            },
            children: [
              {
                index: true,
                element: (
                  <SuspenseWithLoading>
                    <AlertMain />
                  </SuspenseWithLoading>
                ),
              },
              {
                path: 'edit',
                element: (
                  <SuspenseWithLoading>
                    <EditAlert />
                  </SuspenseWithLoading>
                ),
                handle: {
                  crumb: <T _str="Edit alert" />,
                },
              },
              {
                path: ':mailLogId',
                children: [
                  {
                    index: true,
                    element: (
                      <SuspenseWithLoading>
                        <AlertMain />
                      </SuspenseWithLoading>
                    ),
                  },
                  {
                    path: 'view',
                    element: (
                      <SuspenseWithLoading>
                        <ViewAlert />
                      </SuspenseWithLoading>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'frontpages',
        element: (
          <SuspenseWithLoading>
            <FrontPagesView />
          </SuspenseWithLoading>
        ),
      },
      {
        path: 'user-management',
        handle: {
          crumb: <T _str="User management" />,
        },
        element: <UserManagement />,
        children: [
          {
            index: true,
            element: <Navigate to="/user-management/all" />,
          },
          {
            path: ':tab',
            element: (
              <SuspenseWithLoading>
                <UserManagementActionBar />
                <UserManagementView />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'user/:userId',
            handle: {
              crumb: <UserDetailBreadcrumb />,
              dynamic: true,
            },
            element: (
              <SuspenseWithLoading>
                <UserDetail />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'customer_view',
        handle: {
          crumb: <T _str="Customer view" />,
        },
        element: <CustomerViewPage />,
        children: [
          {
            index: true,
            element: (
              <SuspenseWithLoading>
                <CustomerView />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'folders',
        element: (
          <SuspenseWithLoading>
            <FoldersPage />
          </SuspenseWithLoading>
        ),
      },
      {
        path: '*',
        element: <SuspenseWithLoading>{<NotFoundErrorPage />}</SuspenseWithLoading>,
      },
    ],
  },
  {
    path: 'article',
    element: (
      <SuspenseWithLoading>
        <RequireAuthentication as={ArticleView} />
      </SuspenseWithLoading>
    ),
  },
  {
    path: 'mobile',
    element: (
      <SuspenseWithLoading>
        <RequireAuthentication as={MobileView} />
      </SuspenseWithLoading>
    ),
    children: [
      { path: 'article', element: <MobileArticle /> },
      { path: 'search', element: <MobileArticle /> },
      { path: 'folders', element: <MobileFoldersList /> },
      { path: 'list', element: <MobileListPage /> },
    ],
  },
  {
    path: 'callback',
    element: (
      <SuspenseWithLoading>
        <CallbackPage />
      </SuspenseWithLoading>
    ),
  },
  {
    path: 'logged-out',
    element: (
      <SuspenseWithLoading>
        <LoggedOutPage />
      </SuspenseWithLoading>
    ),
  },
  {
    path: '*',
    element: (
      <SuspenseWithLoading>
        <NotFoundErrorPage />
      </SuspenseWithLoading>
    ),
  },
])

const Routes: FC = () => <RouterProvider router={router} />

export default Routes
