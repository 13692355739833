import { MutableRefObject } from 'react'

import { TAG_TYPES } from '../../opoint/tags'
import { AlertTag as AlertTagType, GeneralTag } from '../types/tag'

import { M360Article } from '../../opoint/articles/types'
import AlertTag from './AlertTag'
import KeywordTag from './KeywordTag'
import MentometerTag from './MentometerTag'

export type TagSwitchProps = {
  article: M360Article
  tags: GeneralTag[]
  tagRefs?: MutableRefObject<(HTMLDivElement | null)[]>
  isOverflow?: boolean
  setOverflowDrilldown?: (arg: AlertTagType) => void
  setIdenticalArticleTag?: () => void
  handleUpdateTagWeight?: (increment: boolean) => void
}

const TagSwitch = ({
  article,
  tags,
  tagRefs,
  isOverflow = false,
  setIdenticalArticleTag,
  setOverflowDrilldown,
  handleUpdateTagWeight,
}: TagSwitchProps) => {
  return (
    <>
      {tags.map((tag, index) => {
        switch (tag.type) {
          case TAG_TYPES.KEYWORD:
            return (
              <KeywordTag
                article={article}
                tag={tag}
                key={index}
                isOverflow={isOverflow}
                ref={(ref) => {
                  tagRefs ? (tagRefs.current[index] = ref) : undefined
                }}
                setIdenticalArticleTag={setIdenticalArticleTag}
                handleUpdateTagWeight={handleUpdateTagWeight}
              />
            )
          case TAG_TYPES.MENTOMETER:
            return (
              <MentometerTag
                article={article}
                tag={tag}
                key={index}
                isOverflow={isOverflow}
                ref={(ref) => {
                  tagRefs ? (tagRefs.current[index] = ref) : undefined
                }}
                setIdenticalArticleTag={setIdenticalArticleTag}
                handleUpdateTagWeight={handleUpdateTagWeight}
              />
            )
          case TAG_TYPES.ALERT:
            return (
              <AlertTag
                article={article}
                tag={tag as AlertTagType}
                key={index}
                isOverflow={isOverflow}
                setOverflowDrilldown={setOverflowDrilldown}
                ref={(ref) => {
                  tagRefs ? (tagRefs.current[index] = ref) : undefined
                }}
                setIdenticalArticleTag={setIdenticalArticleTag}
                handleUpdateTagWeight={handleUpdateTagWeight}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}

export default TagSwitch
