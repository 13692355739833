import { T, useT } from '@transifex/react'

import { IMAGES } from '../../../../constants'

import { useSearchFiltersWithoutPeriod } from '../../../hooks/useSearchFilters'
import { getCurrentSearchObj } from '../../../../helpers/location'
import styles from './index.module.scss'

type Props = {
  isSearch?: boolean
  isLimitedSearch?: boolean
  incomparable?: boolean
  message?: string
}

const NoArticlesPage = ({ isSearch, isLimitedSearch = false, incomparable, message }: Props) => {
  const t = useT()
  const searchFilters = useSearchFiltersWithoutPeriod()
  const { expression } = getCurrentSearchObj()

  const getDefaultMessage = () => {
    if (isLimitedSearch) {
      return t('Click on one of the profiles or tags in left column')
    }

    if (!searchFilters.length && !expression) {
      return t('Use search field to search for news or click on one of the profiles in left column')
    }

    if (isSearch) {
      return t('Sorry, this search did not return any hits')
    }

    if (incomparable) {
      return t('Sorry, the previous period had no articles. Please select a longer current period')
    }

    return t('Use search field to search for news or click on one of the profiles in left column')
  }

  return (
    <div data-cy="no-articles" className={styles.wrapper}>
      <img src={IMAGES.no_articles_image} width="212" alt="Search" loading="lazy" />
      <T _str={message || getDefaultMessage()} />
    </div>
  )
}

export default NoArticlesPage
