import always from 'ramda/src/always'
import assoc from 'ramda/src/assoc'
import assocPath from 'ramda/src/assocPath'
import compose from 'ramda/src/compose'
import curry from 'ramda/src/curry'
import dissoc from 'ramda/src/dissoc'
import evolve from 'ramda/src/evolve'
import F from 'ramda/src/F'
import filter from 'ramda/src/filter'
import ifElse from 'ramda/src/ifElse'
import indexBy from 'ramda/src/indexBy'
import mergeAll from 'ramda/src/mergeAll'
import or from 'ramda/src/or'
import prop from 'ramda/src/prop'
import unless from 'ramda/src/unless'

import { formatISO } from 'date-fns'
import { Reducer } from 'redux'
import { AppActions } from '../actions'
import { FilterSuggestion } from '../api/opoint-search-suggest.schemas'
import { SearchFilterKey } from '../components/hooks/useSearchFilters'
import { SearchFilters } from '../components/types/search'
import type { Filter, MultipleSuggestionsOfType } from '../opoint/flow'
import {
  addOneOfKind,
  checkIfSuggestedFilterInverted,
  filterId,
  filterIsProfile,
  filterIsTag,
  filterIsTrashTag,
  invertFilter,
} from '../opoint/search'

export type SearchFilter = { [filterId: string]: Filter }

export type SearchState = {
  abandonedSearchLine: Array<number>
  searchFilters: SearchFilters
  trashTagIds: Array<number>
  profileTagIds: Array<number>
  selectedTagIds: Array<number>
  suggestions: Array<FilterSuggestion>
  suggestionsMultiple: MultipleSuggestionsOfType
  searchInProgress: boolean
  searchIsTakingTooLong: boolean
  loadMoreSearchInProgress: boolean
  meta: {
    foundDocumentsCount: number
    rangeStart?: number
    rangeEnd?: number
    context?: string
    lastTimestamp?: number
    receivedDocumentsCount?: number
    firstTimestamp?: number
    rangeId?: string
    count?: number
  }
  searchDatepicker?: {
    startDate: Date
    endDate: Date
  }
  searchterm: string
  wikinames: {
    [key in number]: string
  }
  filtersShowMore: Array<MultipleSuggestionsOfType>
  wikidescriptions: {
    [key in number]: string
  }
  updatedSoMeMetadataResponse: any
}

export const initialState: SearchState = {
  abandonedSearchLine: [],
  searchFilters: {},
  filtersShowMore: [],
  // We store trash-tags on two places so that we don't need to re-render articles
  // each time filter is added. This is a performance optimisation.
  // PLEASE BE CAREFUL WHEN PLAYING AROUND WITH FILTERS AND ALWAYS CHECK A PERFORMANCE OF THE APP
  trashTagIds: [],
  profileTagIds: [],
  selectedTagIds: [],
  // END
  suggestions: [],
  suggestionsMultiple: {},
  searchInProgress: false,
  searchIsTakingTooLong: false,
  loadMoreSearchInProgress: false,
  meta: {
    foundDocumentsCount: 0,
    // @ts-expect-error: Muted so we could enable TS strict mode
    rangeStart: null,
    // @ts-expect-error: Muted so we could enable TS strict mode
    rangeEnd: null,
    // @ts-expect-error: Muted so we could enable TS strict mode
    context: null,
    firstTimestamp: undefined,
    lastTimestamp: undefined,
    receivedDocumentsCount: undefined,
  },
  // @ts-expect-error: Muted so we could enable TS strict mode
  searchDatepicker: null,
  searchterm: '',
  wikinames: {},
  wikidescriptions: {},
  updatedSoMeMetadataResponse: {},
}

/**
 * This reducer controls how we retrieve suggestions from Opoint's backend.
 * It takes care of toggling filters, inverting them, setting search terms etc.
 *
 * NOTE: It doesn't contain information about complex handling of filters in a profile editor.
 * @see profileReducer for more information how profile editor actions are handled.
 */

const searchReducer: Reducer<SearchState, AppActions> = (state = initialState, action): SearchState => {
  switch (action.type) {
    /**
     * In case a location changed, we want to have the search field load the latest data.
     */
    case 'ROUTER_SEARCH_DATA_CHANGE': {
      const { parsedFilters, expression } = action.payload
      const getNumber = (value: number | string) => (typeof value === 'string' ? parseInt(value, 10) : value)

      // Check if the search term is a single article, and extract the profile id to be used as a filter.
      const singleArticleMatch = expression?.match(/\(profile:(\d+)\)/)
      if (singleArticleMatch) {
        const singleArticleFilter = {
          id: singleArticleMatch[1],
          type: SearchFilterKey.PROFILES,
        }

        parsedFilters.push(singleArticleFilter)
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      return compose(
        assoc('searchterm', or(expression, '')),
        assoc('searchFilters', indexBy(filterId, parsedFilters)),
        assoc(
          'trashTagIds',
          filter(filterIsTrashTag, parsedFilters)?.map((x) => getNumber(x.id)),
        ),
        assoc(
          'profileTagIds',
          filter(filterIsProfile, parsedFilters)?.map((x) => getNumber(x.id)),
        ),
        assoc(
          'selectedTagIds',
          filter(filterIsTag, parsedFilters)?.map((x) => getNumber(x.id)),
        ),
      )(state)
    }
    case 'CLEAR_FORM':
    case 'FEED_REMOVE_ACTIVE':
      return assoc('abandonedSearchLine', [], state)

    /**
     * Clears the global search terms.
     */
    case 'SEARCHDATA_CLEAR': {
      const timePeriod = Object.values(state.searchFilters).filter(
        ({ type }) => type === SearchFilterKey.TIME_PERIOD,
      )[0]

      const searchFilters = timePeriod
        ? {
            [filterId(timePeriod)]: timePeriod,
          }
        : {}

      return {
        ...state,
        searchterm: '',
        searchFilters,
      }
    }

    /**
     * Invert filter given in an payload.
     */
    case 'INVERT_FILTER': {
      const { filter } = action.payload

      return evolve({
        // @ts-expect-error: Muted so we could enable TS strict mode
        searchFilters: compose(dissoc(filterId(filter)), assoc(filterId(invertFilter(filter)), invertFilter(filter))),
      })(state)
    }
    case 'UPDATE_SEARCHTERM_SUCCESS': {
      const { searchterm } = action.payload

      return assoc('searchterm', searchterm, state)
    }

    /**
     * Assoc. filters once they are successfully retrieved.
     */
    case 'FILTERS_FETCH_SUCCESS':
      return assoc('suggestions', action.payload, state)

    /**
     * Assoc. filters in a multiple mode.
     */
    case 'FILTERS_FETCH_MULTIPLE_SUCCESS':
      return assoc('suggestionsMultiple', action.payload, state)

    /**
     * Filters - show more of filter type
     */
    case 'FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS':
      return assoc('filtersShowMore', action.payload, state)

    case 'FILTERS_POP':
      return assoc('filtersShowMore', state.filtersShowMore.slice(0, state.filtersShowMore.length - 1), state)

    /**
     * Remove filter received in an payload
     */
    case 'SEARCHFILTER_REMOVED': {
      const { id } = action.payload
      const isAlertContentTag = /^\d{10}$/.test(id as string)
      const newSearchFilters = { ...state.searchFilters }

      delete newSearchFilters[filterId(action.payload)]

      if (isAlertContentTag) {
        const alertFilter = Object.values(newSearchFilters).find(({ type }) => type === SearchFilterKey.ALERT_ID)
        if (alertFilter) {
          delete newSearchFilters[filterId(alertFilter)]
        }
      }

      return { ...state, searchFilters: newSearchFilters }
    }

    /**
     * Once a search filter is added to a search.
     */
    case 'SEARCHFILTER_ADDED': {
      const filterToBeAdded = action.payload

      return evolve(
        {
          searchFilters: unless(
            prop(filterId(filterToBeAdded)),
            curry(addOneOfKind)(filterId(filterToBeAdded), filterToBeAdded),
          ),
        },
        state,
      )
    }

    /**
     * Add or remove given filter based on current filters state.
     */
    case 'SEARCHFILTER_TOGGLED': {
      // return state
      const filterToBeToggled: Filter = action.payload
      const idFilter: string = checkIfSuggestedFilterInverted(filterId(filterToBeToggled), state.searchFilters)

      return evolve(
        {
          // @ts-expect-error: Muted so we could enable TS strict mode
          searchFilters: ifElse(prop(idFilter), dissoc(idFilter), curry(addOneOfKind)(idFilter, filterToBeToggled)),
        },
        state,
      )
    }

    case 'FETCH_MORE_ARTICLES': {
      return assoc('loadMoreSearchInProgress', true, state)
    }

    /**
     * Functions controlling the state of article's promise.
     */
    case 'FETCH_ARTICLES': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      return compose(assoc('searchInProgress', true), assoc('meta', initialState.meta))(state)
    }

    case 'SEARCH_CANCELED':
    case 'SEARCH_IS_EMPTY': {
      return evolve({
        searchInProgress: F,
        meta: {
          context: always(''),
        },
      })(state)
    }

    case 'PROFILE_EDITOR_PREVIEW': {
      return assoc('searchInProgress', true, state)
    }

    case 'PROFILE_EDITOR_PREVIEW_FAILURE': {
      return assoc('searchInProgress', false, state)
    }

    case 'PROFILE_EDITOR_PREVIEW_SUCCESS': {
      const {
        documents: receivedDocumentsCount,
        range_count: foundDocumentsCount,
        context,
        rangeStart,
        rangeEnd,
        lastTimestamp,
        firstTimestamp,
        wikinames,
        wikidescriptions,
        debug,
      } = action.payload.searchresult

      // @ts-expect-error: Muted so we could enable TS strict mode
      return evolve({
        searchInProgress: always(false),
        searchIsTakingTooLong: always(false),
        loadMoreSearchInProgress: always(false),
        meta: always({
          receivedDocumentsCount,
          foundDocumentsCount, // (range count)
          // might be equal to number of documents, in which case all
          // requested documents were delivered
          // or it might be bigger number which means not all requested
          // were delivered and time range selector should be shown
          rangeStart, // requested range start
          rangeEnd, // requested range end - might be 0 which means now
          lastTimestamp, // oldest - delivered range start
          firstTimestamp, // newest
          context,
        }),
        searchterm: always(debug && debug.lines ? debug.lines[0].query : state.searchterm),
        wikinames: always(wikinames),
        wikidescriptions: always(wikidescriptions),
      })(state)
    }

    case 'FETCH_MORE_ARTICLES_SUCCESS':
    case 'FETCH_MORE_PREVIEW_ARTICLES_SUCCESS':
    case 'FETCH_ARTICLES_SUCCESS':
    case 'FETCH_STATISTICS_SUCCESS': {
      const {
        documents: receivedDocumentsCount,
        range_count: foundDocumentsCount,
        context,
        rangeStart,
        rangeEnd,
        lastTimestamp,
        firstTimestamp,
        wikinames,
        wikidescriptions,
        range_id,
        count,
      } = action.payload.response.searchresult

      // Merge new wiki values with the ones already in the state when fetching more
      const shouldMergeWikis =
        action.type === 'FETCH_MORE_ARTICLES_SUCCESS' || action.type === 'FETCH_MORE_PREVIEW_ARTICLES_SUCCESS'

      const allWikinames = shouldMergeWikis && state.wikinames ? mergeAll([state.wikinames, wikinames]) : wikinames

      const allWikidescriptions =
        shouldMergeWikis && state.wikidescriptions
          ? mergeAll([state.wikidescriptions, wikidescriptions])
          : wikidescriptions

      // TODO: Remove this temporary fix, when fixed in the backend.
      const now = +new Date()
      // In rare cases where no or just a single article have been fetched, the backend returns this Unix as rangeStart: "3791368940" and sometimes "2147483647"
      // If this is the case, then set the date to now.
      const newRangeStart: number = rangeStart > now ? now : rangeStart

      return evolve({
        searchIsTakingTooLong: always(false),
        searchInProgress: always(false),
        loadMoreSearchInProgress: always(false),
        meta: always({
          receivedDocumentsCount,
          foundDocumentsCount, // (range count)
          // might be equal to number of documents, in which case all
          // requested documents were delivered
          // or it might be bigger number which means not all requested
          // were delivered and time range selector should be shown
          rangeStart: newRangeStart, // requested range start
          rangeEnd, // requested range end - might be 0 which means now
          lastTimestamp, // oldest - delivered range start
          firstTimestamp, // newest
          rangeId: range_id,
          context,
          count,
        }),
        wikinames: always(allWikinames),
        wikidescriptions: always(allWikidescriptions),
      })(state)
    }
    case 'FETCH_MORE_ARTICLES_FAILURE':
    case 'FETCH_STATISTICS_FAILURE':
    case 'FETCH_ARTICLES_FAILURE':
      return evolve({
        loadMoreSearchInProgress: always(false),
        searchIsTakingTooLong: always(false),
        searchInProgress: always(false),
      })(state)

    case 'DATEPICKER_MODAL_OPEN': {
      const {
        meta: { rangeEnd, rangeStart },
      } = state

      // Unix timestamp must be converted to iso string since datepicker
      // is returning iso string
      // @ts-expect-error: Muted so we could enable TS strict mode
      return rangeEnd && rangeStart
        ? compose(
            assocPath(['searchDatepicker', 'endDate'], formatISO(rangeEnd)),
            assocPath(['searchDatepicker', 'startDate'], formatISO(rangeStart)),
          )(state)
        : state
    }

    case 'SEARCH_IS_TAKING_TOO_LONG':
      return assoc('searchIsTakingTooLong', true, state)

    case 'CANCEL_SEARCH':
      return evolve({
        searchInProgress: always(false),
        searchIsTakingTooLong: always(false),
      })(state)

    case 'STORE_CURRENT_SEARCHLINE': {
      const { searchLine } = action.payload

      return assoc('abandonedSearchLine', searchLine, state)
    }
    case 'UPDATED_SOME_META_DATA_SUCCESS': {
      const { response } = action.payload

      return assoc('updatedSoMeMetadataResponse', response, state)
    }

    case 'CLEAR_SUGGESTIONS': {
      return {
        ...state,
        suggestions: [],
      }
    }

    default:
      return state
  }
}

export default searchReducer
