import { cn } from '@opoint/infomedia-storybook'
import { memo } from 'react'

import { equals } from 'ramda'
import * as URLService from '../../../../../services/urlService'
import { ArticleImageProps } from '../../../../types/article'
import ArticleImage from '../ArticleImage'
import ImageGallery from '../ImageGallery'

type Props = {
  images: ArticleImageProps[]
  editable: boolean
  id_article: number
  openIndex: number | undefined
  setOpenIndex: (index: number | null) => void
  isSocial?: boolean
}

const ArticleImageGallery = ({ images, editable, id_article, openIndex, setOpenIndex, isSocial }: Props) => (
  <>
    {/* Display the gallery (lightbox) if there's at least one image (hero image) */}
    {/* @ts-expect-error: Muted so we could enable TS strict mode */}
    {images?.length > 0 && <ImageGallery images={images} initialImage={openIndex} setInitialImage={setOpenIndex} />}

    {/* The rest of the images displayed below the article body */}
    {images?.length > 1 && (
      <div
        className={cn({
          'overflow-x-auto flex': !isSocial,
        })}
      >
        {images.slice(1)?.map((articleimage: ArticleImageProps, index: number) => {
          // We're skipping the first when mapping because it's already included as first image above the text
          const idx = index + 1

          return (
            <div
              key={`article_image-${id_article}-${articleimage.url}-${idx}`}
              className="mr-2 w-1/3 shrink-0 last:mr-0"
            >
              <ArticleImage
                keyVal={`image-${id_article}-${articleimage.url}-${idx}`}
                editable={editable}
                imgSrc={articleimage?.url}
                caption={articleimage.caption?.text}
                opointImgSrc={URLService.getThumbnailUrl(articleimage.url, 256)}
                onClick={() => setOpenIndex(idx)}
              />
            </div>
          )
        })}
      </div>
    )}
  </>
)

function arePropsUnchanged(prevProps: Props, nextProps: Props) {
  return (
    prevProps.editable === nextProps.editable &&
    prevProps.id_article === nextProps.id_article &&
    prevProps.openIndex === nextProps.openIndex &&
    equals(prevProps.images, nextProps.images)
  )
}

export default memo(ArticleImageGallery, arePropsUnchanged)
