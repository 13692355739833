import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { concat, EMPTY, from, merge, of } from 'rxjs'
import { catchError, map, mergeMap, retry, switchMap } from 'rxjs/operators'

import { AppActions } from '../actions'
import {
  DeleteArticlesAction,
  DeleteArticlesFailureAction,
  DeleteArticlesProfilesToolbarAction,
  IncrementTagWeightForArticleAction,
  TagArticlesAction,
  TagSingleArticleAction,
  ToggleDeleteArticlesAction,
  UncheckAllArticlesAction,
  UnDeleteArticlesAction,
  UnDeleteArticlesFailureAction,
  UntagArticlesAction,
  UntagSingleArticleAction,
} from '../actions/articles'
import { ImpersonateSuccessAction, LogInSuccessAction } from '../actions/auth'
import { EntityRepositoryRefreshFilterNameAction } from '../actions/entityRepository'
import { FetchFoldersAction } from '../actions/folders'
import {
  AddTagAction,
  AddTagFailureAction,
  DecrementTagWeightForArticleAction,
  DecrementTagWeightForSingleArticleAction,
  DeleteTagAction,
  DeleteTagFailureAction,
  DeleteTagsAction,
  DeleteTagSuccessAction,
  EditTagAction,
  EditTagFailureAction,
  EditTagSuccessAction,
  IncrementTagWeightForSingleArticleAction,
  RemoveTagsFromArticlesAction,
  TagArticlesFailureAction,
  TagsFetchAction,
  TagsFetchFailureAction,
  ToggleTagArticleAction,
  ToggleTagIdenticalArticleAction,
  UntagArticlesFailureAction,
} from '../actions/tags'
import { Tag } from '../components/types/tag'
import { cannotDeleteEntityReason } from '../opoint/common'
import { ArticleTag } from '../opoint/flow'
import {
  addTag,
  deleteTag,
  editTag,
  getDecrementedWeight,
  getIncrementedWeight,
  getTags,
  isEveryArticleInGroupUntagged,
  isTheWholeGroupUntagged,
  tagArticles,
  tagSingleArticle,
  untagArticles,
  untagSingleArticle,
} from '../opoint/tags'
import { deleteArticles, getTrashTags, undeleteArticles } from '../opoint/tags/trash'
import { RootState } from '../reducers'
import { getAlertArticleTagById, getCurrentAlertMail } from '../selectors/alertsSelectors'
import { getArticleTagById, getSelectedArticles } from '../selectors/articlesSelectors'
import { getSelectedProfilesIds } from '../selectors/searchSelectors'
import { getTrashTagById } from '../selectors/trashTagsSelectors'

import { M360Article } from '../opoint/articles/types'
import { GoToDefaultProfileAction } from '../actions/profiles'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

export const fetchEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, TagsFetchAction | LogInSuccessAction | ImpersonateSuccessAction>(
      'TAGS_FETCH',
      'LOG_IN_SUCCESS',
      'IMPERSONATE_SUCCESS',
    ),
    switchMap(() =>
      from(getTags()).pipe(
        map((data) => ({ type: 'TAGS_FETCH_SUCCESS', payload: data })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<TagsFetchFailureAction>({ type: 'TAGS_FETCH_FAILURE' })),
      ),
    ),
  )

export const addEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, AddTagAction>('ADD_TAG'),
    switchMap(({ payload: { tag } }) =>
      from(addTag(tag))?.pipe(
        map((data) => ({ type: 'ADD_TAG_SUCCESS', payload: { tag: data } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<AddTagFailureAction>({ type: 'ADD_TAG_FAILURE' })),
      ),
    ),
  )

export const editEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, EditTagAction>('EDIT_TAG'),
    switchMap(({ payload: { tag } }) => {
      return from(editTag(tag))?.pipe(
        map((data) => ({ type: 'EDIT_TAG_SUCCESS', payload: { tag: data } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<EditTagFailureAction>({ type: 'EDIT_TAG_FAILURE' })),
      )
    }),
  )
export const editSuccessEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, EditTagSuccessAction>('EDIT_TAG_SUCCESS'),
    mergeMap(() => {
      return concat(
        of<FetchFoldersAction>({
          type: 'FETCH_FOLDERS',
        }),
        of<EntityRepositoryRefreshFilterNameAction>({ type: 'REFRESH_FILTERS_NAME' }),
      )
    }),
  )

export const deleteMultipleEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, DeleteTagsAction>('DELETE_TAGS'),
    switchMap(({ payload }) =>
      from(payload.tagIds).pipe(
        mergeMap((tagId) =>
          from(deleteTag(tagId)).pipe(
            map(() => ({ type: 'DELETE_TAG_SUCCESS', payload: { tagId } })),
            catchError((error) => {
              const parsedResponse = JSON.parse(error.originalEvent.target.response)
              const errorReason = cannotDeleteEntityReason(parsedResponse)

              return of<DeleteTagFailureAction>({
                type: 'DELETE_TAG_FAILURE',
                payload: { error: errorReason },
              })
            }),
          ),
        ),
      ),
    ),
  )

export const deleteEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, DeleteTagAction>('DELETE_TAG'),
    switchMap(({ payload }) =>
      from(deleteTag(payload.tagId)).pipe(
        mergeMap(() =>
          of<DeleteTagSuccessAction | GoToDefaultProfileAction>(
            { type: 'DELETE_TAG_SUCCESS', payload: { tagId: payload.tagId } },
            { type: 'GO_TO_DEFAULT_PROFILE' },
          ),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((e) => {
          const parsedResponse = JSON.parse(e.originalEvent.target.response)
          const error = cannotDeleteEntityReason(parsedResponse)

          return of<DeleteTagFailureAction>({ type: 'DELETE_TAG_FAILURE', payload: { error } })
        }),
      ),
    ),
  )

export const toggleEpic: (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<AppActions, ToggleTagArticleAction>('TOGGLE_TAG_ARTICLE'),
    mergeMap(({ payload: { articles, tag } }) => {
      // Fixes some weird cornercase when no article is passed to this epic
      if (!articles) {
        return EMPTY
      }

      const state = state$.value
      const articlesArray = (Array.isArray(articles) ? articles : [articles]) as M360Article[]
      const alertContent = getCurrentAlertMail(state)
      const firstArticle = articlesArray[0]

      const articleTag = alertContent
        ? firstArticle && getAlertArticleTagById(tag.id, firstArticle.id_article)(state)
        : firstArticle && getArticleTagById(tag, firstArticle)(state)

      return of<UntagArticlesAction | TagArticlesAction>(
        articleTag
          ? { type: 'UNTAG_ARTICLES', payload: { articles: articlesArray, tagId: tag.id } }
          : { type: 'TAG_ARTICLES', payload: { articles: articlesArray, tag } },
      )
    }),
  )

export const toggleIdenticalArticleEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ToggleTagIdenticalArticleAction>('TOGGLE_TAG_IDENTICAL_ARTICLE'),
    mergeMap(({ payload: { article, tag, originalArticle } }) => {
      // Fixes some weird cornercase when no article is passed to this epic
      if (!article) {
        return EMPTY
      }

      const isTagged = !!article.tags?.[tag.id]

      return of<UntagSingleArticleAction | TagSingleArticleAction>(
        isTagged
          ? { type: 'UNTAG_SINGLE_ARTICLE', payload: { article, tag, originalArticle } }
          : { type: 'TAG_SINGLE_ARTICLE', payload: { article, tag, originalArticle } },
      )
    }),
  )

export const tagEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, TagArticlesAction>('TAG_ARTICLES'),
    mergeMap(({ payload: { articles, tag, weight } }) => {
      const articlesArray = Array.isArray(articles) ? articles : [articles]

      // @ts-expect-error: Muted so we could enable TS strict mode
      return from(tagArticles(articlesArray, tag, weight)).pipe(
        switchMap(() => of()),
        retry(3),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<TagArticlesFailureAction>({ type: 'TAG_ARTICLES_FAILURE' })),
      )
    }),
  )

export const untagEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, UntagArticlesAction>('UNTAG_ARTICLES'),
    mergeMap(({ payload: { articles, tagId } }) => {
      const articlesArray = Array.isArray(articles) ? articles : [articles]

      return from(untagArticles(articlesArray, tagId)).pipe(
        switchMap(() => of()),
        retry(3),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<UntagArticlesFailureAction>({ type: 'UNTAG_ARTICLES_FAILURE' })),
      )
    }),
  )

export const tagSingleArticleEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, TagSingleArticleAction>('TAG_SINGLE_ARTICLE'),
    mergeMap(({ payload: { article, tag, weight, originalArticle } }) => {
      let probablyTagGroup$ = of()
      if (isTheWholeGroupUntagged(originalArticle, tag)) {
        probablyTagGroup$ = of<TagArticlesAction>({
          type: 'TAG_ARTICLES',
          payload: {
            articles: [
              {
                ...originalArticle,
                identical_documents: {
                  cnt: 1,
                  document: [article],
                },
              },
            ],
            tag,
            toTagOnlyMainArticle: true,
          },
        })
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      const tagSingleArticle$ = from(tagSingleArticle(article, tag, weight)).pipe(
        switchMap(() => of()),
        retry(3),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<TagArticlesFailureAction>({ type: 'TAG_ARTICLES_FAILURE' })),
      )

      return merge(tagSingleArticle$, probablyTagGroup$)
    }),
  )

export const untagSingleArticleEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, UntagSingleArticleAction>('UNTAG_SINGLE_ARTICLE'),
    mergeMap(({ payload: { article, tag, originalArticle } }) => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      if (isEveryArticleInGroupUntagged(originalArticle.identical_documents.document, tag, article)) {
        return of<UntagArticlesAction>({
          type: 'UNTAG_ARTICLES',
          payload: {
            articles: [originalArticle],
            tagId: tag.id,
          },
        })
      }

      return from(untagSingleArticle(article, tag)).pipe(
        switchMap(() => of()),
        retry(3),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<UntagArticlesFailureAction>({ type: 'UNTAG_ARTICLES_FAILURE' })),
      )
    }),
  )

export const incrementEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, IncrementTagWeightForArticleAction>('INCREMENT_TAG_WEIGHT_FOR_ARTICLE'),
    map(({ payload: { articles, tag } }) => {
      // refactor naming to articles
      const state = state$.value
      const articlesArray = Array.isArray(articles) ? articles : [articles]
      const alertContent = getCurrentAlertMail(state)

      const articleTag = alertContent
        ? getAlertArticleTagById(tag.id, articlesArray[0].id_article)(state)
        : getArticleTagById(tag, articlesArray[0])(state) || {}

      const weight = getIncrementedWeight({
        articleTag: articleTag as ArticleTag | undefined,
        tag: tag as Tag,
      })

      return {
        type: 'TAG_ARTICLES',
        payload: {
          articles: articlesArray,
          tag,
          weight,
        },
      }
    }),
  )

export const decrementEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, DecrementTagWeightForArticleAction>('DECREMENT_TAG_WEIGHT_FOR_ARTICLE'),
    map(({ payload: { articles, tag } }) => {
      const articlesArray = Array.isArray(articles) ? articles : [articles]

      const articleTag = (getArticleTagById(tag, articlesArray[0])(state$.value) || {}) as ArticleTag

      const weight = getDecrementedWeight({
        articleTag,
        tag: tag as Tag,
      })

      return {
        type: 'TAG_ARTICLES',
        payload: {
          articles: articlesArray,
          tag,
          weight,
        },
      }
    }),
  )

export const incrementSingleArticleEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, IncrementTagWeightForSingleArticleAction>('INCREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE'),
    map(({ payload: { identicalArticle, lastUsedTag, originalArticle } }) => {
      const articleTag = identicalArticle.tags?.[lastUsedTag.id]
      const weight = getIncrementedWeight({
        articleTag,
        tag: lastUsedTag,
      })

      return {
        type: 'TAG_SINGLE_ARTICLE',
        payload: {
          article: identicalArticle,
          tag: lastUsedTag,
          weight,
          originalArticle,
        },
      }
    }),
  )

export const decrementSingleArticleEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, DecrementTagWeightForSingleArticleAction>('DECREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE'),
    map(({ payload: { identicalArticle, lastUsedTag, originalArticle } }) => {
      const articleTag = identicalArticle?.tags?.[lastUsedTag.id]
      const weight = getDecrementedWeight({
        articleTag,
        tag: lastUsedTag,
      })

      return {
        type: 'TAG_SINGLE_ARTICLE',
        payload: {
          article: identicalArticle,
          tag: lastUsedTag,
          weight,
          originalArticle,
        },
      }
    }),
  )

export const fetchTrashEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    switchMap(() =>
      from(getTrashTags()).pipe(
        map((data) => ({ type: 'TRASH_FETCH_SUCCESS', payload: { trashTags: data } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of()),
      ),
    ),
  )

export const toggleTrashTagEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ToggleDeleteArticlesAction>('TOGGLE_DELETE_ARTICLES'),
    map(({ payload: { article, tag } }) => {
      const articleTag = getArticleTagById(tag, article)(state$.value)

      return articleTag
        ? { type: 'UNDELETE_ARTICLES', payload: { articles: article, tag } }
        : { type: 'DELETE_ARTICLES', payload: { articles: article, tag } }
    }),
  )

export const deleteArticlesEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, DeleteArticlesAction>('DELETE_ARTICLES'),
    switchMap(({ payload: { articles, tag } }) => {
      const articlesArray = Array.isArray(articles) ? articles : [articles]

      return from(deleteArticles(articlesArray, tag)).pipe(
        switchMap(() => of()),
        retry(3),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<DeleteArticlesFailureAction>({ type: 'DELETE_ARTICLES_FAILURE' })),
      )
    }),
  )

export const undeleteArticlesEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, UnDeleteArticlesAction>('UNDELETE_ARTICLES'),
    switchMap(({ payload: { articles, tag } }) => {
      const articlesArray = Array.isArray(articles) ? articles : [articles]

      return from(undeleteArticles(articlesArray.filter(Boolean), tag)).pipe(
        switchMap(() => of()),
        retry(3),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<UnDeleteArticlesFailureAction>({ type: 'UNDELETE_ARTICLES_FAILURE' })),
      )
    }),
  )

export const deleteArticleProfilesToolbarEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, DeleteArticlesProfilesToolbarAction>('DELETE_ARTICLES_PROFILES_TOOLBAR'),
    switchMap(() => {
      const trashTags = getSelectedProfilesIds(state$.value)?.map((id) => getTrashTagById(id)(state$.value))
      const articles = getSelectedArticles(state$.value)

      const deleteObservables$ = trashTags.map((tag) =>
        //@ts-expect-error - Muted because of the type mismatch
        of<DeleteArticlesAction>({ type: 'DELETE_ARTICLES', payload: { articles, tag } }),
      )

      return concat(...deleteObservables$, of<UncheckAllArticlesAction>({ type: 'UNCHECK_ALL_ARTICLES' }))
    }),
  )

export const removeTagsFromArticlesEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, RemoveTagsFromArticlesAction>('REMOVE_TAGS_FROM_ARTICLES'),
    switchMap(({ payload }) => {
      const articles = getSelectedArticles(state$.value) as M360Article[]
      const tags = (payload || []).filter((item) => item.type === 'tag')
      const untagArticles = tags.map(({ id }) =>
        of<UntagArticlesAction>({
          type: 'UNTAG_ARTICLES',
          payload: { articles: articles, tagId: Number(id) },
        }),
      )

      return concat(
        ...untagArticles,
        of<UncheckAllArticlesAction>({
          type: 'UNCHECK_ALL_ARTICLES',
        }),
      )
    }),
  )

export default [
  addEpic,
  decrementEpic,
  decrementSingleArticleEpic,
  deleteArticleProfilesToolbarEpic,
  deleteArticlesEpic,
  deleteEpic,
  editEpic,
  fetchEpic,
  fetchTrashEpic,
  incrementEpic,
  incrementSingleArticleEpic,
  removeTagsFromArticlesEpic,
  tagEpic,
  tagSingleArticleEpic,
  toggleEpic,
  toggleIdenticalArticleEpic,
  toggleTrashTagEpic,
  undeleteArticlesEpic,
  untagEpic,
  untagSingleArticleEpic,
  deleteMultipleEpic,
  editSuccessEpic,
]
