import { useCallback, useEffect, useRef, useState } from 'react'

import { isTag } from '../../guards/tags'
import { isTrashTag } from '../../helpers/tags'
import { GeneralTag } from '../types/tag'

import { M360Article } from '../../opoint/articles/types'
import OverflowTagsPopover from './OverflowTagsPopover'
import TagSwitch from './TagSwitch'

export type ArticleTagsProps = {
  article: M360Article
  tags: GeneralTag[]
  tagsContainer: 'uploadArticleFooter' | 'articleFooter'
  setIdenticalArticleTag?: () => void
  handleUpdateTagWeight?: (increment: boolean) => void
}

const ArticleTags = ({
  article,
  tags,
  tagsContainer,
  setIdenticalArticleTag,
  handleUpdateTagWeight,
}: ArticleTagsProps) => {
  const tagRefs = useRef<HTMLDivElement[]>([])
  const [threshold, setThreshold] = useState(Infinity)

  const settingThreshold = useCallback(() => {
    const offsets: number[] = []

    if (tagsContainer !== 'uploadArticleFooter') {
      for (let index = 0; index < tagRefs.current.length; index++) {
        const element = tagRefs.current[index]

        if (!element) {
          break
        }

        if (!offsets.includes(element.offsetTop)) {
          if (offsets.length === 2) {
            setThreshold(index)
            break
          } else {
            offsets.push(element.offsetTop)
          }
        }
      }
    }
  }, [tagsContainer])

  useEffect(() => {
    settingThreshold()
  }, [tags, settingThreshold])

  const tagsWithoutTrashTags = tags.filter((tag) => {
    return !isTrashTag(tag)
  })

  const tagsSorted = tagsWithoutTrashTags.sort((a, b) => {
    return a.type - b.type
  })

  const tagsToSlice = tagsSorted.filter((tag) => article.tags?.[tag.id] || (isTag(tag) && tag.visibility === 2))

  const shownTags = tagsToSlice.slice(0, threshold)
  const overflowTags = tagsToSlice.slice(threshold, tagsToSlice.length)
  const tagsToShow = tagsContainer === 'uploadArticleFooter' ? tagsSorted : shownTags

  const windowResizeHandler = useCallback(() => {
    settingThreshold()
  }, [settingThreshold])

  useEffect(() => {
    const scrollContainer = document.getElementById('article-previews')

    if (!scrollContainer) {
      return
    }

    window.addEventListener('resize', windowResizeHandler)
    setTimeout(windowResizeHandler, 100)

    return () => {
      window.removeEventListener('resize', windowResizeHandler)
    }
  }, [windowResizeHandler])

  return (
    <div className="flex justify-between">
      <div className="flex flex-wrap justify-start gap-2.5">
        {tagsToShow.length > 0 && (
          <TagSwitch
            article={article}
            tags={tagsToShow}
            tagRefs={tagRefs}
            setIdenticalArticleTag={setIdenticalArticleTag}
            handleUpdateTagWeight={handleUpdateTagWeight}
          />
        )}
      </div>
      {tagsContainer !== 'uploadArticleFooter'
        ? overflowTags.length > 0 && (
            <OverflowTagsPopover tags={overflowTags} article={article}>
              <button className="flex h-[1.875rem] min-w-[3.5rem] cursor-pointer items-center rounded bg-grey.7 px-3 text-label-l text-sky.1">
                + {overflowTags.length}
              </button>
            </OverflowTagsPopover>
          )
        : null}
    </div>
  )
}

export default ArticleTags
