import { useEffect, useMemo, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import { HighlightedMatchesAndEntities } from '@opoint/infomedia-storybook'
import { ArticleImageProps } from '../../../../types/article'

/**
 * Component for rendering a gallery of images
 *  @param {Array<ArticleImageProps>} images
 *  @param {number | null} initialImage
 *  @param {(index: number | null) => void} setInitialImage
 *  @return {JSXElement} ImageGallery
 */

export type Props = {
  images: Array<ArticleImageProps>
  initialImage: number | null
  setInitialImage: (index: number | null) => void
}

const ImageGallery = ({ images, initialImage, setInitialImage }: Props) => {
  const [currentImage, setCurrentImage] = useState<number | null>(initialImage ?? null)

  useEffect(() => {
    document.body.setAttribute('stop-propagation', 'true')

    return () => {
      document.body.removeAttribute('stop-propagation')
    }
  }, [])

  useEffect(() => {
    setCurrentImage(initialImage)
  }, [initialImage])

  // @ts-expect-error: Muted so we could enable TS strict mode
  const clickPrevImage = () => setCurrentImage(currentImage - 1)
  // @ts-expect-error: Muted so we could enable TS strict mode
  const clickNextImage = () => setCurrentImage(currentImage + 1)
  const closeGalleryHandler = () => {
    setCurrentImage(null)
    setInitialImage(null)
  }

  const shouldRender = useMemo(() => {
    return (
      // @ts-expect-error: Muted so we could enable TS strict mode
      !!images && images.length > 0 && !isNaN(currentImage) && currentImage !== null && currentImage < images.length
    )
  }, [currentImage, images?.length])

  /* The id is needed so that we don't scroll through the articles when the image gallery is open */
  return shouldRender ? (
    <div id="lightboxGallery">
      <Lightbox
        // @ts-expect-error: Muted so we could enable TS strict mode
        mainSrc={images[currentImage].url}
        // @ts-expect-error: Muted so we could enable TS strict mode
        nextSrc={currentImage < images.length - 1 ? images[currentImage + 1].url : undefined}
        // @ts-expect-error: Muted so we could enable TS strict mode
        prevSrc={currentImage > 0 ? images[currentImage - 1].url : undefined}
        onCloseRequest={closeGalleryHandler}
        onMovePrevRequest={clickPrevImage}
        onMoveNextRequest={clickNextImage}
        // @ts-expect-error: Muted so we could enable TS strict mode
        imageCaption={<HighlightedMatchesAndEntities text={images[currentImage].caption?.text} />}
        clickOutsideToClose={true}
      />
    </div>
  ) : null
}

export default ImageGallery
