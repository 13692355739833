import { Link20Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { DateVariant, HighlightedMatchesAndEntities, formatDate } from '@opoint/infomedia-storybook'

import { SOCIAL_SOURCES } from '../../../../opoint/common/constants'
import { alterSoMeSummary } from '../../../helpers/common'
import { SoMeAttachments } from '../../../types/article'
import { getDateVariant, getOpointLocale } from '../../../../selectors/settingsSelectors'
import SocialAuthor from '../SocialAuthor/SocialAuthor'
import { useAppSelector } from '../../../hooks/useAppSelector'

import styles from './index.module.scss'

type Props = {
  type: string
  author?: string
  content_protected?: number
  first_source_id?: number
  renderSocialIcon?: (profileImage?: string, profileUrl?: string) => JSX.Element
  soMeAttachments?: SoMeAttachments
  isTwitterContent?: boolean
  video?: boolean
}

const SocialContent = ({
  author,
  content_protected,
  first_source_id,
  isTwitterContent,
  renderSocialIcon,
  soMeAttachments,
  type,
  video,
}: Props) => {
  const locale = useAppSelector(getOpointLocale)
  const dateVariant: DateVariant = useAppSelector(getDateVariant)

  const [imageLoadError, setImageLoadError] = useState<boolean>(false)

  if (!soMeAttachments) {
    return null
  }

  const QuotedAuthor = () => {
    // @ts-expect-error: Muted so we could enable TS strict mode
    const quotedAuthor = soMeAttachments.quoted_tweet.author
    const quotedTweet = soMeAttachments.quoted_tweet

    return (
      <div className={styles['quote_container']}>
        <div className={styles['quote_social_header']}>
          {/* @ts-expect-error: Muted so we could enable TS strict mode */}
          {renderSocialIcon(quotedAuthor.profile_image, quotedTweet.link)}
          <div className="flex flex-col text-xs leading-5 text-sky.cloudy">
            {quotedTweet?.posted_time &&
              formatDate({
                dateVariant,
                localeName: locale,
                unixTimestamp: Math.floor(+new Date(quotedTweet.posted_time) / 1000),
              })}
            <SocialAuthor
              author={author}
              content_protected={content_protected}
              hasHandle={!!quotedAuthor.username}
              isVerified={quotedAuthor.verified}
              orig_url={quotedTweet?.link}
              showSmall={!isTwitterContent}
              url={quotedTweet?.link}
              username={quotedAuthor.username}
              display_name={quotedAuthor.display_name}
            />
          </div>
        </div>
        <div className={styles.text}>
          <HighlightedMatchesAndEntities
            // @ts-expect-error: Muted so we could enable TS strict mode
            socialSource={SOCIAL_SOURCES[first_source_id]?.name}
            // @ts-expect-error: Muted so we could enable TS strict mode
            text={alterSoMeSummary(quotedTweet.text, quotedTweet.content_type)}
          />
        </div>
      </div>
    )
  }

  if (video) {
    // @ts-expect-error: Muted so we could enable TS strict mode
    if (type === 'video' && soMeAttachments.media?.length > 0) {
      // Only the Facebook videos are supported at the moment
      // @ts-expect-error: Muted so we could enable TS strict mode
      const facebookVideos = soMeAttachments.media.filter(({ url }) => url?.includes('facebook'))

      if (!facebookVideos.length) {
        return null
      }

      return (
        <>
          {facebookVideos.map((video, index) => {
            const videoId = encodeURIComponent(video.url)

            return (
              <div key={index} className="relative w-auto overflow-hidden pt-[100%]">
                <iframe
                  src={`https://www.facebook.com/plugins/video.php?height=476&href=${videoId}&show_text=false&width=476&t=0`}
                  className="absolute inset-0 h-full w-full"
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            )
          })}
        </>
      )
    }

    return null
  }

  if (type === 'quote') {
    return <QuotedAuthor />
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  if (type === 'link' && soMeAttachments.links?.length > 0) {
    return (
      <>
        {soMeAttachments?.links?.map((link, index) => {
          const url = link?.url ? new URL(link.url) : { hostname: '' }

          return (
            <a
              key={index}
              href={link.url}
              className="flex flex-col bg-grey.7 text-label-l text-sky.1 hover:text-sky.1"
              rel="noreferrer noopener"
              target="_blank"
            >
              {link.image && !imageLoadError && (
                <img
                  className={styles.image}
                  src={link.image}
                  alt={link.title}
                  onError={() => setImageLoadError(true)}
                />
              )}

              <div className="flex flex-col space-y-2 p-2">
                {link.title && <span>{link.title}</span>}
                {link.description && <span>{link.description}</span>}

                {link.url && (
                  <span className="flex items-center space-x-2">
                    <Link20Regular />
                    <span className="mb-0">{link.site_name ? link.site_name : url.hostname}</span>
                  </span>
                )}
              </div>
            </a>
          )
        })}
      </>
    )
  }

  return null
}

export default SocialContent
