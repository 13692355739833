import { Popover, PopoverContent, PopoverTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import classNames from 'classnames'
import { ReactNode } from 'react'

import Button from '../../../../common/Button'
import { SentimentType } from '../../../../types/article'

import styles from './index.module.scss'

const sentimentStates = [SentimentType.Positive, SentimentType.Neutral, SentimentType.Negative]

const SentimentButton = ({
  type,
  isActive,
  onClick,
}: {
  type: SentimentType
  isActive: boolean
  onClick: (sentiment: SentimentType) => void
}) => (
  <div className={classNames({ [styles.active]: isActive })}>
    <Button name="link" type="button" onClick={() => onClick(type)} className={styles.outerCircle}>
      <div className={classNames(styles.innerCircle, styles[type])}></div>
    </Button>
  </div>
)

type Props = {
  isManuallySet: boolean
  onCompanySentimentChange: (boolean) => void
  shownCompanySentiment: string
  children: ReactNode
}

const SentimentPopover = ({ isManuallySet, children, shownCompanySentiment, onCompanySentimentChange }: Props) => {
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent className={styles.sentimentPopover} side="bottom" variant="legacy">
        <div className={styles.sentimentPopoverContent}>
          <div className={styles.popoverMid}>
            {shownCompanySentiment === SentimentType.Notset ? (
              <T
                _str="We were not able to automatically set your company sentiment on this article. Click below to set it
          manually."
              />
            ) : (
              <T _str="You can manually override our automatically generated assessment below" />
            )}
          </div>
          <div className={styles.popoverBottom}>
            <div>
              {sentimentStates.map((sentimentState, index) => (
                <SentimentButton
                  key={`${sentimentState}-${index}`}
                  isActive={isManuallySet && shownCompanySentiment === sentimentState}
                  type={sentimentState}
                  onClick={onCompanySentimentChange}
                />
              ))}
            </div>
            {isManuallySet && (
              <Button name="link" type="button" onClick={() => onCompanySentimentChange(SentimentType.Notset)}>
                <T _str="Reset selection" />
              </Button>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default SentimentPopover
