import { cn, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { resolveCompanySentiment } from '../../../../../helpers/listing'
import { getIsSentimentAvailable } from '../../../../../helpers/sentiment'
import { getUpdatedCompanySentiments } from '../../../../../selectors/sentimentSelectors'
import ModulePermissions from '../../../../common/ModulePermissions'
import { LICENSE_MODULES } from '../../../../constants/permissions'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import useSentimentAttributes from '../../../../hooks/useSentimentAttributes'
import {
  CompanySentimentDetails,
  sentimentToManualMapping,
  SentimentType,
  UpdatedSentimentType,
} from '../../../../types/article'

import { articleIdFromIds } from '../../../../../opoint/articles'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { M360Article } from '../../../../../opoint/articles/types'
import styles from './index.module.scss'
import SentimentPopover from './SentimentPopover'

type ArticleSentimentProps = {
  article: M360Article
  generalSentiment: SentimentType
  companySentimentDetails?: Array<CompanySentimentDetails>
}

const ArticleSentiment = ({ article, companySentimentDetails, generalSentiment }: ArticleSentimentProps) => {
  const dispatch = useAppDispatch()

  // We're only interested in having the first group.
  // Groups were created for future purposes.
  const { auto, id_group, manual } = (companySentimentDetails && companySentimentDetails[0]) || {}
  const { id_article, id_site, language } = article || {}

  const isSentimentAvailable = getIsSentimentAvailable(language?.text)

  // If the company sentiment is manually set, it is saved in the redux to avoid needing to refetch the article.
  const updatedCompanySentiment: UpdatedSentimentType | undefined =
    useAppSelector(getUpdatedCompanySentiments)[articleIdFromIds(id_site, id_article)]

  const isManuallySetCompanySentiment =
    (updatedCompanySentiment && updatedCompanySentiment !== 'Reset') || (manual && manual !== 'unset')

  const companySentiment = resolveCompanySentiment(auto, manual, updatedCompanySentiment)

  const {
    showCompanySentiment,
    showGeneralSentiment,
    canOverride: isPermittedToManuallyOverride,
  } = useSentimentAttributes()

  const handelOverrideCompanySentiment = (newSentiment: SentimentType) => {
    dispatch({
      type: 'OVERRIDE_COMPANY_SENTIMENT',
      payload: {
        id_group,
        article: { id_article, id_site, manual_sentiment: sentimentToManualMapping[newSentiment] },
      },
    })
  }

  if (!isSentimentAvailable || (!showGeneralSentiment && !showCompanySentiment)) {
    return null
  }

  return (
    <ModulePermissions
      module="INFOMEDIA_SENTIMENT_DATA"
      permissions={[LICENSE_MODULES.INFOMEDIA_SENTIMENT_DATA.SET, LICENSE_MODULES.INFOMEDIA_SENTIMENT_DATA.ENFORCE]}
      type="license"
    >
      <li className="flex flex-col space-y-2.5">
        <div className="whitespace-nowrap text-label-s font-bold text-sky.cloudy">
          <T _str="Sentiment" />
        </div>

        <div className="flex">
          {showGeneralSentiment && (
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex h-6 w-6 items-center justify-center rounded-full border border-grey.5 hover:border-sand.3">
                  <div
                    className={cn('h-[1.125rem] w-[1.125rem] rounded-full', {
                      'bg-forest.3': generalSentiment === SentimentType.Positive,
                      'bg-sand.4': generalSentiment === SentimentType.Neutral,
                      'bg-berry.4': generalSentiment === SentimentType.Negative,
                      'bg-grey.6': generalSentiment === SentimentType.Notset,
                    })}
                  ></div>
                </div>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent side="top">
                  <span className={styles.sentimentTooltip}>
                    {generalSentiment === SentimentType.Notset ? (
                      <T _str="We were not able to determine sentiment on this article." />
                    ) : (
                      <T _str="{generalSentiment}" generalSentiment={generalSentiment} />
                    )}
                  </span>
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )}

          {showGeneralSentiment && showCompanySentiment && <div className="mx-1.5 h-6 w-[0.0625rem] bg-grey.6" />}

          {showCompanySentiment && (
            <Tooltip>
              <TooltipTrigger>
                <SentimentPopover
                  // @ts-expect-error: Muted so we could enable TS strict mode
                  isManuallySet={isManuallySetCompanySentiment}
                  onCompanySentimentChange={handelOverrideCompanySentiment}
                  shownCompanySentiment={companySentiment}
                >
                  <button
                    onClick={(e) => {
                      if (!isPermittedToManuallyOverride) {
                        e.stopPropagation()
                      }
                    }}
                    className={cn(
                      'flex h-6 w-6 items-center justify-center rounded-full border border-grey.5 hover:border-sand.3',
                      { 'cursor-default': !isPermittedToManuallyOverride },
                    )}
                  >
                    <div
                      className={cn('h-[1.125rem] w-[1.125rem] rounded-full', {
                        'bg-forest.3': companySentiment === SentimentType.Positive,
                        'bg-sand.4': companySentiment === SentimentType.Neutral,
                        'bg-berry.4': companySentiment === SentimentType.Negative,
                        'bg-grey.6': companySentiment === SentimentType.Notset,
                      })}
                    ></div>
                  </button>
                </SentimentPopover>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent side="top">
                  <span className={cn(styles.sentimentTooltip, { [styles.editable]: isPermittedToManuallyOverride })}>
                    <T _str="Your Organization: " />
                    {companySentiment === SentimentType.Notset ? (
                      <T _str="Not set" />
                    ) : (
                      <T _str="{companySentiment}" companySentiment={companySentiment} />
                    )}
                    {isPermittedToManuallyOverride && (
                      <p className={styles.clickToChange}>
                        <T _str="Click to change" />
                      </p>
                    )}
                  </span>
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )}
        </div>
      </li>
    </ModulePermissions>
  )
}

export default ArticleSentiment
