import { useState, useEffect } from 'react'
import { convertToHTML } from 'draft-convert'
import Draft, { ContentState, Editor, EditorState, getSafeBodyFromHTML } from 'draft-js'
import { Map } from 'immutable'

import isTextValid from '../../../../../helpers/isTextValid'

const blockRenderMap = Map({
  header: {
    element: 'h2',
  },
  'header-one': {
    element: 'h1',
  },
  paragraph: {
    element: 'p',
  },
  unstyled: {
    element: 'span',
  },
})

export type EditableTextProps = {
  bodyText?: string
  onContentChange?: ({ body: { text } }: { body: { text: string } }) => void
}

const EditableText = (props: EditableTextProps) => {
  const [editorState, setEditorState]: any = useState()

  useEffect(() => {
    const { bodyText } = props

    const newEditorState = EditorState.createWithContent(
      bodyText && isTextValid(bodyText)
        ? ContentState.createFromBlockArray(Draft.convertFromHTML(bodyText, getSafeBodyFromHTML, blockRenderMap))
        : ContentState.createFromText(''),
    )

    setEditorState(newEditorState)
  }, [])

  const onChange = (newEditorState) => {
    const { onContentChange } = props

    setEditorState(newEditorState)
    const html = convertToHTML({
      blockToHTML: (block) => {
        if (block.type === 'header') {
          return <h1>{block.text}</h1>
        }
      },
    })(newEditorState.getCurrentContent())

    onContentChange?.({
      body: {
        text: html,
      },
    })
  }

  return editorState ? <Editor editorState={editorState} onChange={onChange} blockRenderMap={blockRenderMap} /> : null
}

export default EditableText
