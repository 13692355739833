import { useOpointUser } from '@opoint/authjs-react'
import { shouldShowTag } from '../../../../../helpers/tags'
import { M360Article } from '../../../../../opoint/articles/types'
import { getAllTagLikeEntities } from '../../../../../selectors/tagsComposedSelectors'
import ModulePermissions from '../../../../common/ModulePermissions'
import { MODULES } from '../../../../constants/permissions'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import ArticleTags from '../../../../tags/Tag'
import { isAllTagsHidden } from '../../../../../selectors/settingsSelectors'

type ArticleFooterProps = {
  article: M360Article
  originalArticle: M360Article
  breakToPopup?: boolean
}

const ArticleFooter = (props: ArticleFooterProps) => {
  const { article, originalArticle, breakToPopup = true } = props

  const tagsFn = useAppSelector(getAllTagLikeEntities)
  const hideAllTags = useAppSelector(isAllTagsHidden)

  const tags = hideAllTags ? [] : tagsFn(article)?.filter((tag) => shouldShowTag(tag))
  const user = useOpointUser()

  return (
    // TODO Consider renaming article and activeArticle so they match
    <>
      {(user?.is_staff || user?.impersonator?.is_staff) && (
        <div className="text-label-s font-bold text-sky.cloudy">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://tools.opoint.com/AI/art_info.php?id_site=${article.id_site}&id_article=${article.id_article}`}
          >
            id:{article.id_site}_{article.id_article}
          </a>
        </div>
      )}
      <div className="relative items-center pt-6 font-sans">
        {/* Article tags */}
        <ModulePermissions module="TAG_MODULE" permissions={[MODULES.TAG_MODULE.ON]}>
          <ArticleTags
            article={originalArticle || article}
            tags={tags}
            /* TODO: FIND OUT IF THIS SHOULD CONTAIN SOMETHING ELSE */
            tagsContainer={breakToPopup ? 'articleFooter' : 'uploadArticleFooter'}
          />
        </ModulePermissions>
      </div>
    </>
  )
}

export default ArticleFooter
